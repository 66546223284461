/*eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageWrapper, NotificationWrapper, Button, Text, TopHeading, ReadUnreadButton, Container, NotificationType } from "./styles";
import momentTZ from 'moment-timezone';
import isEmpty from "lodash/isEmpty";
import { convertDateInTimezone, convertMonthFromDate, getMainConstraint } from "../../utils/methods";
import { fetchGetUserNotification, getListOfConnectedDevices, getReadStatusCountForNotification, notificationReadStatus, revokeDevice, FetchSurveyQuestions, notificationMarkAsRead } from "../../redux/actions";
import { connect } from "react-redux";
import Waiting from "../Waiting";
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { IconContainer } from "../TopBarV2/style";
import ConnectDeviceModal from "../ConnectDeviceModal";
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import PeerRecognition from '../../components/PeerRecognition';
import moment from "moment";

const notificationIcon = (
  <svg className={'notification-bell'} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g mask="url(#mask0)">
      <path d="M19.441 15.503C18.0806 14.3317 17.3001 12.6184 17.3001 10.8046V8.24999C17.3001 5.02432 14.9473 2.35388 11.9004 1.90707V0.917113C11.9004 0.409881 11.4967 0 11 0C10.5033 0 10.0996 0.409881 10.0996 0.917113V1.90707C7.05138 2.35388 4.69987 5.02432 4.69987 8.24999V10.806C4.69987 12.6197 3.91944 14.3317 2.55072 15.5114C2.20104 15.8165 2 16.2606 2 16.7296C2 17.6138 2.70595 18.3342 3.57536 18.3342H18.4246C19.2927 18.3342 20 17.6151 20 16.7296C20 16.2606 19.799 15.8165 19.441 15.503Z" fill="none" />
      <path d="M11.0001 22.0001C12.6296 22.0001 13.993 20.8174 14.3061 19.2501H7.69287C8.00728 20.8174 9.37073 22.0001 11.0001 22.0001V22.0001Z" fill="none" />
    </g>
  </svg>
);

const units = {
  "day": 24 * 60,
  "hour": 60,
  "minute": 1
};
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seeMore: true,
      showNotification: false,
      showDeviceModal: false,
      buttonAll: true,
      buttonUnread: false,
      showPeerModal: false,
      shoutOutData:{}
    };
    this.myref = React.createRef();
  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({ showNotification: false });
    }
  };
  toggleNotification = () => {
    const { fetchGetUserNotification, getReadStatusCountForNotification } = this.props;
    fetchGetUserNotification();
    getReadStatusCountForNotification();
    this.setState((prev) => ({
      showNotification: !prev.showNotification
    }));
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    const { fetchGetUserNotification, getReadStatusCountForNotification, fetchConnectedDevices } = this.props;
    fetchGetUserNotification();
    getReadStatusCountForNotification();
    fetchConnectedDevices();
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  peerCallBack = (index, name, uid, icon) => {
    let obj = {};
    if (index === 0) {
      obj.core_value = "Happy Birthday";
      obj.image = "../../../public/images/social/birthdayShoutOut.png";
      obj.examplifyId = 19;
      //obj.icon = "social-recognition/birthday.png",
      obj.name = name,
      obj.uid = uid,
      obj.profile_image=icon
    } else {
      obj.core_value = "Work Anniversary";
      obj.image = "../../../public/images/social/anniversaryShoutOut.png";
      obj.examplifyId = 20;
      //obj.icon = "social-recognition/anniversary.png";
      obj.name = name,
      obj.uid = uid,
      obj.profile_image=icon
    }

    this.setState((prev) => ({
      shoutOutData: { ...obj },
      showPeerModal: !prev.showPeerModal
    }))
  }

  clickNotification = (id, type, notificationId) => {
    window.console.log("shubham: ", id);
    const { history, notificationReadStatus, getReadStatusCountForNotification, fetchGetUserNotification } = this.props;
    getReadStatusCountForNotification();
    fetchGetUserNotification();
    let obj = {};
    obj['notification_type'] = type;
    obj['notification_type_id'] = notificationId;
    obj['read_status'] = 1;
    notificationReadStatus(obj);
    (type === 'add_initiative') ? history.push(`/portal`) : (type === 'event' || type === 'event_end' || type === 'add_event') ? history.push(`/events/${id}`) : type === 'event_comment' ? history.push({pathname:`/events/${id}`,state:"chat"}) : (type === 'challenge' || type === 'challenge_end' || type === 'add_challenge' || type === 'challenge_comment' || type === 'challenge_invite') ? history.push(`/challenges/${id}`) : (type === 'request_accept_or_reject' || type === 'friend_request') ? history.push(`/community/manage-buddies`) :  (type === 'add_launch_quiz') ? history.replace(`/wellness-resource/quiz-details/${id}`) :(type === 'device_disconnect' || type === 'add_survey') ? null : history.push(`/social`);
    (type === 'device_disconnect') ? this.displayDeviceModal(): (type === 'comment_like')? history.push(`/social`): null;
    this.toggleNotification();
  };

  clickHris = (id, type, notificationId) => {
    window.console.log("shubham: ", id);
    const { history, notificationReadStatus, getReadStatusCountForNotification, fetchGetUserNotification } = this.props;
    getReadStatusCountForNotification();
    fetchGetUserNotification();
    let obj = {};
    obj['notification_type'] = type;
    obj['notification_type_id'] = notificationId;
    obj['read_status'] = 1;
    notificationReadStatus(obj);
    history.push(`/company/hris-integration`);
    this.toggleNotification();
  };

  clickFeedNotification = (id, type, notificationId, commentId) => {
    const { history, notificationReadStatus, getReadStatusCountForNotification, fetchGetUserNotification } = this.props;
    getReadStatusCountForNotification();
    fetchGetUserNotification();
    let obj = {};
    obj['notification_type'] = type;
    obj['notification_type_id'] = notificationId;
    obj['read_status'] = 1;
    notificationReadStatus(obj);
    // history.push(`/social/${notificationId}`)
    if(type=="post_comment"||type=="post_comment_reply"||type=="comment_reply"||type=="post_like"||type=="tagged_comment_reply"||type=="comment_like"||type=="tagged_post_comment"){
      history.push({pathname:`/social`,state:commentId})
    }
    else{
      history.push({pathname:`/social`,state:notificationId})
    }
    this.toggleNotification();
  };

  clickFeedNotificationCommentMention = (id, type, notificationId, commentId) => {
    const { history, notificationReadStatus, getReadStatusCountForNotification, fetchGetUserNotification } = this.props;
    getReadStatusCountForNotification();
    fetchGetUserNotification();
    let obj = {};
    obj['notification_type'] = type;
    obj['notification_type_id'] = notificationId;
    obj['read_status'] = 1;
    notificationReadStatus(obj);
    // history.push(`/social/${notificationId}`)
    if(type=="post_comment"||type=="post_comment_reply"||type=="comment_reply"||type=="post_like"){
      history.push({pathname:`/social`,state:commentId})
    }
    else{
      history.push({pathname:`/social`,state:commentId})
    }
    this.toggleNotification();
  };

  showList = () => {
    const { userNotificationList } = this.props;
    this.setState({ seeMore: !this.state.seeMore });
    this.classifyNotification(userNotificationList, 0, userNotificationList.length)
  };

  removeHtmlTags=(inputHtml)=> {
    let doc = new DOMParser().parseFromString(inputHtml, 'text/html');
    return doc.body.textContent || "";
  }

  getArticle=(word) =>{
    if (!word || typeof word !== 'string') return ''; // Check if word is valid
    
    const firstLetter = word[0].toLowerCase();
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    
    // Check if the first letter is a vowel
    if (vowels.includes(firstLetter)) {
      return `an`;
    } else {
      return `a`;
    }
  }

  classifyUnreadNotification = (userNotificationList, startIndex, endIndex, unreadCount) => {
    const { buttonAll } = this.state;
    const { t, userId, user } = this.props;
    const notificationSlicedArray = this.state.seeMore ? userNotificationList : userNotificationList.slice(startIndex, endIndex);
    if (unreadCount === 0) {
      return (<NotificationType>{t("No Notification")}</NotificationType>)
    }
    else {
      return notificationSlicedArray.map((item, index) => {
        const { read_status: readStatus } = item;
        const prevDate = item['created_at'];
        const firstdate = moment(prevDate);
        const date = new Date();
        const currentdate = date;
        let diff = Math.abs(getMainConstraint(firstdate, currentdate));
        let newResult = [];
        for (let name in units) {
          let p = Math.floor(diff / units[name]);
          if (newResult.length < 2) {
            if (p === 1) newResult.push(p + " " + name);
            if (p >= 2) newResult.push(p + " " + name + "s");
            diff %= units[name];
          }
        }
        if (newResult.length === 0 || (newResult[0].indexOf("minute") >= 0) || (newResult[0].indexOf("hour") >= 0)) {
          newResult = [];
          newResult.push(t("Today"));
        } else {
          newResult[0] = newResult[0] + " " + t('ago');
        }
        switch (item.type) {
          case 'reply_recognition': {
            const { fname, lname } = item.data['posted_by'];
            const { shoutout_type: shoutoutType } = item.data['recognition_details'];
            const { recognition_image: imageUrl } = item.data['recognition_details'];
            const { core_value: coreValue } = item.data['recognition_details'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', item.type, item.type_id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
              <p>
                <a>{`${fname} ${lname}`}</a>
                <span>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? ` ${t("is saying")} ` : ` ${t("is recognizing")} `}</span><Text>{`${coreValue} `}</Text>
                <a>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? ' to you ' : null}{` ${item.type === 'recognition' ? '' : item.type === 'comment_recognition' ? 'in a comment' : item.type === 'reply_recognition' ? 'in a comment reply' : null}`}</a>
              </p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', item.type, item.type_id)}>
                <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
                </ImageWrapper>
                <p>
                  <a>{`${t(fname)} ${t(lname)}`}</a>
                  <span>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? ` ${t("is saying")} ` : ` ${t("is recognizing")} `}</span><Text>{`${t(coreValue)} `}</Text>
                  <a>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? t(' to you ') : null}{` ${item.type === 'recognition' ? '' : item.type === 'comment_recognition' ? t('in a comment') : item.type === 'reply_recognition' ? t('in a comment reply') : null}`}</a>
                </p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'add_event': {
            return buttonAll ?
              <li onClick={() => this.clickHris('', 'add_new_hris_users', item.type_id)}>
                <ImageWrapper width="50px" height="50px">
                  <img onError={this.fallBack} src={`${ImageUrl}/${"Hris-image/hrisNotification.svg"}`} />
                </ImageWrapper>
                <p>
                  <span>Hi {user?.fname},</span>&nbsp;
                  <b>{item?.data?.new_user_count} users</b>&nbsp;
                  <span>we’re added to {user?.company_name} from your HRIS integration.</span>
                </p>
                <div className="showDot">
                  {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li onClick={() => this.clickHris('', 'add_new_hris_users', item.type_id)}>
                <ImageWrapper width="50px" height="50px">
                  <img onError={this.fallBack} src={`${ImageUrl}/${"Hris-image/hrisNotification.svg"}`} />
                </ImageWrapper>
                <p>
                  <span>Hi {user?.fname},</span>&nbsp;
                  <b>{item?.data?.new_user_count} users</b>&nbsp;
                  <span>we’re added to {user?.company_name} from your HRIS integration.</span>
                </p>
                <div className="showDot">
                  {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
            }
          case 'recognition':{
            const { fname, lname } = item.data['posted_by'];
            const { shoutout_type: shoutoutType } = item.data['recognition_details'];
            const { recognition_image: imageUrl } = item.data['recognition_details'];
            const { core_value: coreValue } = item.data['recognition_details'];
            return buttonAll && (item?.data?.taggedUserCount===1|| item?.data?.taggedUserCount>1 ||item?.data?.tagged_count===1 || item?.data?.tagged_count>1) ?<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
              {(item?.data?.taggedUserCount===1 ||item?.data?.tagged_count===1 )?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives you")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition")}</span>
                </p>:
                item?.data?.taggedUserCount===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.name}`}</b>
                </p>:
                item?.data?.tagged_count===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.name}`}</b>
                </p>:
                item?.data?.tagged_count>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.tagged_count-1} others`}</b>
                </p>:
                item?.data?.taggedUserCount>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.taggedUserCount-1} others`}</b>
                </p>:null
              }
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>:
            !item.read_status && (item?.data?.taggedUserCount===1||item?.data?.tagged_count===1||item?.data?.taggedUserCount>1||item?.data?.tagged_count>1)?<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
            <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
              <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
            </ImageWrapper>
            {(item?.data?.taggedUserCount===1||item?.data?.tagged_count===1)?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives you")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition")}</span>
                </p>:
                item?.data?.taggedUserCount===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.lname}`}</b>
                </p>:
                item?.data?.tagged_count===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.lname}`}</b>
                </p>:
                item?.data?.tagged_count>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.tagged_count-1} others`}</b>
                </p>
                :
                item?.data?.taggedUserCount>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.taggedUserCount-1} others`}</b>
                </p>:null
              }
            <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
          buttonAll ?<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
          <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
            <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
          </ImageWrapper>
          <p>
            <b>{`${fname} ${lname}`}</b> <span>{this.props.t("gives you have")}</span>&nbsp;<b>{coreValue}</b>&nbsp;<span>{this.props.t("recognition")}</span>
          </p>
          <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
          </div>
          <h5>- {newResult[0]}</h5>
        </li>:
        !item.read_status &&<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
        <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
          <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
        </ImageWrapper>
        <p>
          <b>{`${fname} ${lname}`}</b> <span>{this.props.t("gives you have")}</span>&nbsp;<b>{coreValue}</b>&nbsp;<span>{this.props.t("recognition")}</span>
        </p>
        <div className="showDot">
          {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
        </div>
        <h5>- {newResult[0]}</h5>
      </li>
          }
          case 'recognition_other': {
            const { recognition_image: imageUrl } = item.data['recognition_details'];
            const { core_value: coreValue } = item.data['recognition_details'];
            return buttonAll&&(item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)||(item?.data?.taggedUserCount>1) ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
                <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
                </ImageWrapper>
                {(item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.recognized_to?.fname} `}{`${item?.data?.recognized_to?.lname}. `}</b></p>:
            item?.data?.taggedUserCount===2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.second_mention?.fname} `}{`${item?.data?.second_mention?.lname}. `}</b></p>:
            item?.data?.taggedUserCount>2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.taggedUserCount-1} others.`}</b></p>:
              null
            }
              <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>:
            !item.read_status && (item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)|| (item?.data?.taggedUserCount>1)?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
            <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
              <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
            </ImageWrapper>
            {(item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.recognized_to?.fname} `}{`${item?.data?.recognized_to?.lname}. `}</b></p>:
            item?.data?.taggedUserCount===2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.second_mention?.fname} `}{`${item?.data?.second_mention?.lname}. `}</b></p>:
            item?.data?.taggedUserCount>2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.taggedUserCount-1} others.`}</b></p>:
              null
            }
          <div className="showDot">
          {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
          </div>
          <h5>- {newResult[0]}</h5>
        </li>:
        buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
        <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
          <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
        </ImageWrapper>
      <p><b>{`${item.data.posted_by.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>gives</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{this.props.t("recognition")} {this.props.t("to")}</span><b>{` ${item?.data?.recognized_user?.fname} `}{`${item?.data?.recognized_user?.lname}. `}</b></p>
      <div className="showDot">
      {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
      </div>
      <h5>- {newResult[0]}</h5>
    </li>:
    !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
    <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
      <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
    </ImageWrapper>
  <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>gives</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{this.props.t("recognition")} {this.props.t("to")}</span><b>{` ${item?.data?.recognized_user?.fname} `}{`${item?.data?.recognized_user?.lname}. `}</b></p>
  <div className="showDot">
  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
  </div>
  <h5>- {newResult[0]}</h5>
</li>
          }
          case 'add_event': {
            const { title } = item.data['event_details'] !== undefined ? item.data['event_details'] : {};
            const { id } = item.data['event_details']
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_event', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                <img src={'/public/images/notification/event_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{this.props.t("Woliba Event")} : </b>{title && <a>{`${title} Event`}</a>}<span> {this.props.t("is here")} </span><b>{this.props.t("Join now")}</b></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_event', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                  <img src={'/public/images/notification/event_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{t("Woliba Event")} : </b><a>{`${t(title)} ${t("Event")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'event': {
            const { title, id } = item.data['event_details'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                <img src={'/public/images/notification/event_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{this.props.t("Woliba Event")} : </b><a>{`${title} Event`}</a><span> {this.props.t("is here")} </span><b>{this.props.t("Join now")}</b></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                  <img src={'/public/images/notification/event_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{t("Woliba Event")} : </b><a>{`${t(title)} ${t("Event")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'event_end': {
            const { title, event_points: eventPoints, id } = item.data['event_details'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event_end', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                <img src={'/public/images/notification/event_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{`${this.props.t("Event")}  ${this.props.t(title)} `}</b><span>{this.props.t("Running event has ended")}</span><a>{` ${this.props.t("you've earned")} ${eventPoints} ${this.props.t("pts")}`}</a></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event_end', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
                  <img src={'/public/images/notification/event_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${t("Event")}  ${t(title)} `}</b><span>{t("Running event has ended")}</span><a>{` ${t("you've earned")} ${eventPoints} ${t("pts")}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'post_mention_other': {
            return item.data.posted_by.uid==userId?null:buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.mentioned_other_user.fname} `}{`${item.data.mentioned_other_user.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.mentioned_other_user.fname} `}{`${item.data.mentioned_other_user.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'post_mention_other_2': {
            return item.data.posted_by.uid==userId?null:buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_2',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span>and</span><b>{` ${item.data.second_mention.fname} `}{`${item.data.second_mention.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_2',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span>{this.props.t("and")}</span><b>{` ${item.data.second_mention.fname} `}{`${item.data.second_mention.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'post_mention_other_3': {
            return item.data.posted_by.uid==userId?null:buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_3',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span> {this.props.t("and")}</span><b>{` ${item.data.mentioned_user.length-1} others `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_3',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span> {this.props.t("and")}</span><b>{` ${item.data.mentioned_user.length-1} others `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'post_mention': {
            return buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            {
            item.data.mention_others?
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{item.data.mention_others} {this.props.t("others")}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
            :item.data.mentioned_user.length>1
            ?
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{userId==item.data?.first_mention?.uid?item.data.second_mention?.fname:item.data.first_mention?.fname}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
              :
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned you in a post")}</span></p>}
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            {
            item.data.mention_others?
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{item.data.mention_others} {this.props.t("others")}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
            :item.data.mentioned_user.length>1
            ?
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{userId==item.data.first_mention?.uid?item.data.second_mention?.fname:item.data?.first_mention?.fname}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
              :
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned you in a post")}</span></p>}
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'tagged_post_comment': {
            return buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_post_comment',item.type_id, item.data.comment_details[0].post_id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${item.data.commented_by.fname} `}{`${item.data.commented_by.lname} `}</b><span>{this.props.t("commented on Post you are tagged in.")}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_post_comment',item.type_id, item.data.comment_details[0].post_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.commented_by.fname} `}{`${item.data.commented_by.lname} `}</b><span>{this.props.t("commented on Post you are tagged in.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'tagged_comment_reply': {
            return buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_comment_reply',item.type_id, item.data.reply_details.post_id)}>
            <ImageWrapper>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.replied_by.fname} `}{`${item.data.replied_by.lname} `}</b><span>{this.props.t("replied on Post You are tagged in.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
            !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_comment_reply',item.type_id, item.data.reply_details.post_id)}>
            <ImageWrapper>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.replied_by.fname} `}{`${item.data.replied_by.lname} `}</b><span>{this.props.t("replied on Post You are tagged in.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          case 'comment_recognition': {
            const { fname, lname } = item.data['posted_by'];
            return buttonAll ?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotificationCommentMention("", 'comment_recognition',item.type_id, item.data.recognition_details.post_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${fname} ${lname} `}</b><span>{this.props.t("mentioned you in")} <b>{`${item.data.post_details&& item.data.post_details.fname} ${item.data.post_details&&item.data.post_details.lname} `}</b> {this.props.t("post comment.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:
          !item.read_status &&<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotificationCommentMention("", 'comment_recognition',item.type_id, item.data.recognition_details.post_id)}>
          <ImageWrapper >
            <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
          </ImageWrapper>
          <p><b>{`${fname} ${lname} `}</b><span>{this.props.t("mentioned you in")} <b>{`${item.data.post_details&& item.data.post_details.fname} ${item.data.post_details&&item.data.post_details.lname} `}</b> {this.props.t("post comment.")}</span></p>
          <div className="showDot">
          {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
          </div>
          <h5>- {newResult[0]}</h5>
        </li>
          }
          case 'add_user_anniversary': {
            return buttonAll ?<li key={index} className={readStatus && 'read'} >
            <ImageWrapper >
            {userId!=item.data.user_details.uid?
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
              <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/anniversaryNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
            </ImageWrapper>
            {userId!=item.data.user_details.uid?<p><span>{this.props.t("Give Shout out")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b><span>{` ${this.props.t(on)} ${item.data.user_details.anniversary_num} ${this.props.t("Year Work Anniversary")} ` }({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
            <p><span>{this.props.t("Congratulations")}</span><b>{`, ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b> <span>{this.props.t("for Successful")}</span><b> {` ${item.data.user_details.anniversary_num} ${this.props.t("Year at")} ${item.data.user_details.company_name}!`}</b></p>}
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
              <div className='button' style={{width:"100px !important"}}>
                <div className='wish' onClick={()=>this.peerCallBack(1, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Shout Out")}</div>
              </div>
              <div className='time'>- {newResult[0]}</div>
            </div>:
            <h5>- {newResult[0]}</h5>}
          </li>:
          !item.read_status &&<li key={index} className={readStatus && 'read'} >
          <ImageWrapper >
          {userId!=item.data.user_details.uid?
            <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
            <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/anniversaryNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
          </ImageWrapper>
          {userId!=item.data.user_details.uid?<p><span>{this.props.t("Give Shout out")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b><span>{` ${this.props.t("on")} ${item.data.user_details.anniversary_num} ${this.props.t("Year Work Anniversary")} ` }({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
          <p><span>{this.props.t("Congratulations")}</span><b>{`, ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b> <span>{this.props.t("for Successful")}</span><b> {` ${item.data.user_details.anniversary_num} Year at ${item.data.user_details.company_name}!`}</b></p>}
          <div className="showDot">
          {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
          </div>
          {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
            <div className='button' style={{width:"100px !important"}}>
              <div className='wish' onClick={()=>this.peerCallBack(1, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Shout Out")}</div>
            </div>
            <div className='time'>- {newResult[0]}</div>
          </div>:
          <h5>- {newResult[0]}</h5>}
        </li>
          }
          case 'add_user_birthday': {
            return buttonAll ?<li key={index} className={readStatus && 'read'} >
            <ImageWrapper >
            {userId!=item.data.user_details.uid?
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
              <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/birthdayNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
            </ImageWrapper>
            {userId!=item.data.user_details.uid?<p><span>{this.props.t("Wish")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{this.props.t("a happy birthday.")} ({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
            <p><span>{this.props.t("Hey")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{this.props.t("Wish You a Happy Birthday!")}</span></p>}
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
              <div className='button' style={{width:"100px"}}><div className='wish' onClick={()=>this.peerCallBack(0, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Wish Them")}</div></div>
              <div className='time'>- {newResult[0]}</div>
            </div>:
            <h5>- {newResult[0]}</h5>}
          </li>:
          !item.read_status &&<li key={index} className={readStatus && 'read'} >
          <ImageWrapper >
          {userId!=item.data.user_details.uid?
            <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
            <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/birthdayNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
          </ImageWrapper>
          {userId!=item.data.user_details.uid?<p><span>{this.props.t("Wish")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{this.props.t("a happy birthday.")} ({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
          <p><span>{this.props.t("Hey")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{this.props.t("Wish You a Happy Birthday!")}</span></p>}
          <div className="showDot">
          {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
          </div>
          {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
            <div className='button' style={{width:"100px"}}><div className='wish' onClick={()=>this.peerCallBack(0, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Wish Them")}</div></div>
            <div className='time'>- {newResult[0]}</div>
          </div>:
          <h5>- {newResult[0]}</h5>}
        </li>
          }
          case 'post_like': {
            // const { fname, lname } = item.data['liked_by'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_like',item.type_id, item.data.post_details.id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${item.data && item.data.liked_by && item.data.liked_by.fname && item.data.liked_by.fname} ${item.data && item.data.liked_by && item.data.liked_by.lname && item.data.liked_by.lname}`}</b><span> {this.props.t("likes")}</span><a> {this.props.t("your post")}</a></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_like',item.type_id, item.data.post_details.id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${t(item.data && item.data.liked_by && item.data.liked_by.fname && item.data.liked_by.fname)} ${t(item.data && item.data.liked_by && item.data.liked_by.lname && item.data.liked_by.lname)}`}</b><span> {t("likes")}</span><a> {t("your post")}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'post_comment': {
            // const {fname,  lname } = item.data['commented_by'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_comment',item.type_id, item.data.comment_details.post_id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${item.data && item.data.commented_by && item.data.commented_by.fname && item.data.commented_by.fname} ${item.data && item.data.commented_by && item.data.commented_by.lname && item.data.commented_by.lname}`}</b><span> {this.props.t("commented")} </span><a> {this.props.t("on your post")}</a></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_comment',item.type_id, item.data.comment_details[0].post_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${t(item.data.commented_by.fname)} ${t(item.data && item.data.commented_by && item.data.commented_by.lname && item.data.commented_by.lname)}`}</b><span> {t("commented")} </span><a> {t("on your post")}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'add_initiative': {
            const { id, initiative_name, initiative_image } = item.data['initiative_details'];
            return buttonAll ? <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_initiative', item.type_id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${initiative_image}`} alt="initiative" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
              </ImageWrapper>
              <p><b>{this.props.t("Company initiative")} :</b><a> {`${this.props.t("Your Company has Launched ")} ${this.getArticle(initiative_name)}`} </a><b>{this.props.t(initiative_name)} </b><a>{this.props.t("initiative to complete.")}</a></p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_initiative', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${initiative_image}`} alt="initiative" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><b>{t("Company initiative")} :</b><a> {`${this.props.t("Your Company has Launched ")} ${this.getArticle(initiative_name)}`} </a><b>{t(initiative_name)} </b><a>{t("initiative to complete")}.</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          // else if (item.type == 'biometric') {
          //   return <li>
          //     <ImageWrapper backgroundColor={'#D2408A'}>
          //       <img src={'/public/images/notification/biometric.svg'}/>
          //     </ImageWrapper>
          //     <p><a>{item.text.status}</a><a>{item.text.sender}</a><span>{`${item.text.msg} Today`}</span></p>
          //     <h5>- 2 days ago</h5>
          //   </li>
          // }
          case 'challenge_end': {
            const { title, id, challenge_point: challengePoint } = item.data['challenge_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge_end', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`Challenge  ${title}`}</b><span> {this.props.t("Running challenge has ended")} </span><a>{`${this.props.t("you've earned")} ${challengePoint} ${this.props.t("pts")}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge_end', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${t("Challenge")}  ${t(title)}`}</b><span> {t("Running challenge has ended")} </span><a>{`${t("you've earned")} ${challengePoint} ${t("pts")}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'challenge': {
            const { title, id } = item.data['challenge_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{this.props.t("Woliba Challenge")} : </b><a>{`${this.props.t(title)} ${this.props.t("Challenge")}`}</a><span> {this.props.t("is here")} </span><b>{this.props.t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]} {this.props.t("ago")}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{t("Woliba Challenge")} : </b><a>{`${t(title)} ${t("Challenge")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]} {t("ago")}</h5>
              </li>
          }
          case 'add_challenge': {
            // const { title, id } = item.data['challenge_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(item.data && item.data.challenge_details && item.data.challenge_details.id && item.data.challenge_details.id, 'add_challenge', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{this.props.t("Woliba Challenge")} : </b><a>{`${item.data && item.data.challenge_details && item.data.challenge_details.title && item.data.challenge_details.title} ${this.props.t("Challenge")}`}</a><span> {this.props.t("is here")} </span><b>{this.props.t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(/* id */ item?.data?.challenge_details?.id, 'add_challenge', item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{t("Woliba Challenge")} : </b><a>{`${t(item.data && item.data.challenge_details && item.data.challenge_details.title && item.data.challenge_details.title)} ${t("Challenge")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'add_launch_quiz': {
            // const { title, id } = item.data['challenge_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(item.data && item.data.launch_quiz_details && item.data.launch_quiz_details.id && item.data.launch_quiz_details.id, 'add_launch_quiz', item.type_id)}>
               <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.launch_quiz_details.image}`} alt="Quiz" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><b>{this.props.t("Join the Fun and Take the Quiz!")}  </b><a>{`${item.data && item.data.launch_quiz_details && item.data.launch_quiz_details.title && item.data.launch_quiz_details.title}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(/* id */ item?.data?.launch_quiz_details?.id, 'add_launch_quiz', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.launch_quiz_details.image}`} alt="Quiz" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><b>{t("Join the Fun and Take the Quiz!")} </b><a>{`${t(item.data && item.data.launch_quiz_details && item.data.launch_quiz_details.title && item.data.launch_quiz_details.title)}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'challenge_comment': {
            const { title, challenge_id: challengeId } = item.data['challenge_comment_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(challengeId, 'challenge_comment', item.type_id)}>
                <ImageWrapper backgroundColor={'#69c2ff'}>
                  <img src={'/public/images/notification/comment_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${item.data && item.data.challenge_comment_details && item.data.challenge_comment_details.fullname && item.data.challenge_comment_details.fullname}`}</b><span> {this.props.t("commented on")} </span><a> {`${this.props.t(title)}`}</a> {this.props.t("challenge")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(challengeId, 'challenge_comment', item.type_id)}>
                <ImageWrapper backgroundColor={'#69c2ff'}>
                  <img src={'/public/images/notification/comment_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${t(item.data && item.data.challenge_comment_details && item.data.challenge_comment_details.fullname && item.data.challenge_comment_details.fullname)}`}</b><span> {t("commented on")} </span><a> {`${t(title)}`}</a> {t("challenge")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'event_comment': {
            const { fullname, title, event_id: eventId } = item.data['event_comment_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(eventId, 'event_comment', item.type_id)}>
                <ImageWrapper backgroundColor={'#69c2ff'}>
                  <img src={'/public/images/notification/comment_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${fullname}`}</b><span> {this.props.t("commented on")} </span><a> {`${this.props.t(title)}`}</a> {this.props.t("Event")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(eventId, 'event_comment', item.type_id)}>
                <ImageWrapper backgroundColor={'#69c2ff'}>
                  <img src={'/public/images/notification/comment_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${t(fullname)}`}</b><span> {t("commented on")} </span><a> {`${t(title)}`}</a> {t("Event")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'friend_request': {
            const { fname, lname, profile_image } = item.data['sent_by'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'friend_request', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><span>{this.props.t("You Have a Buddy Request")} :</span> <b>{`${fname} ${lname}`}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'friend_request', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><span>{t("You Have a Buddy Request")} :</span> <b>{`${t(fname)} ${t(lname)}`}</b></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'device_disconnect': {
            const { device_name: deviceName } = item.data && item.data['device_details'] ? item.data['device_details'] : {};
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'device_disconnect', item.type_id)}>
                <ImageWrapper backgroundColor={'#FD7175'}>
                  <img src={'/public/images/notification/device.png'} />
                </ImageWrapper>
                <p>{this.props.t("Your device")} <b>{`${deviceName}`}</b><span> {this.props.t("got disconnected from Woliba")}</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'device_disconnect', item.type_id)}>
                <ImageWrapper backgroundColor={'#FD7175'}>
                  <img src={'/public/images/notification/device.png'} />
                </ImageWrapper>
                <p>{t("Your device")} <b>{`${t(deviceName)}`}</b><span> {t("got disconnected from Woliba")}</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'photo_post': {
            const { fname, lname } = item.data['posted_by'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'photo_post', item.data.post_details.id)}>
                <ImageWrapper backgroundColor={'rgba(156, 156, 156, 0.1)'}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.post_details.image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${fname} ${lname}`}</b><span> {this.props.t("posted a photo")}.</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'photo_post', item.data.post_details.id)}>
                <ImageWrapper backgroundColor={'rgba(156, 156, 156, 0.1)'}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.post_details.image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("posted a photo.")}</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'request_accept_or_reject': {
            const { status } = item.data['friend_request_details'];
            const { fname, lname, profile_image } = item.data['invitee_id'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'request_accept_or_reject', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><b>{`${fname} ${lname}`}</b><span>{` ${this.props.t("has")} ${status} ${this.props.t("your Buddy Request")}`}</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'request_accept_or_reject', item.type_id)}>
                <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
                <p><b>{`${t(fname)} ${t(lname)}`}</b><span>{` ${t("has")} ${t(status)} ${t("your Buddy Request")}`}</span></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'post_comment_reply': {
            // const { fname, lname } = item.data['replied_by'];
            return isEmpty(item.data) ? '' : buttonAll ?
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
            <ImageWrapper  width={'30px'}>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your")} ${item.type === 'comment_reply' ? t('comment') : t('post')}`}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
              <ImageWrapper  width={'30px'}>
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your")} ${item.type === 'comment_reply' ? t('comment') : t('post')}`}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
          }
            case 'comment_reply': {
              // const { fname, lname } = item.data['replied_by'];
              return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
              <ImageWrapper  width={'30px'}>
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your comment of")} `}</span><b>{`${item.data.posted_user_details&& item.data.posted_user_details.fname} ${item.data.posted_user_details&& item.data.posted_user_details.lname} `}</b>Post</p>
              <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li> :
                !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
                <ImageWrapper  width={'30px'}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your comment of")} `}</span><b>{`${item.data.posted_user_details&& item.data.posted_user_details.fname} ${item.data.posted_user_details&& item.data.posted_user_details.lname} `}</b>{this.props.t("Post")}</p>
                <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
            }
          case 'add_survey': {
            const { survey_name: surveyName, id } = item.data['survey_details'];
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.takeSurvey(item.type_id, id,item.data)}>
                <ImageWrapper >
                  <img src={'/public/images/notification/survey_icon.png'} style={{ "width": "50px", "height": "50px" }} />
                </ImageWrapper>
                <p><span style={{fontFamily:"Rubik-Medium"}}>{this.props.t("Company Surveys")} :</span> {this.props.t("Your Company has Launched a")}<a><span style={{fontFamily:"Rubik-Medium"}}>{`${item.data['survey_details']?.title}`} </span></a>{this.props.t("Provide your Response  which will help them to Improve.")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.takeSurvey(item.type_id, id, item.data)}>
                <ImageWrapper >
                  <img src={'/public/images/notification/survey_icon.png'} style={{ "width": "50px", "height": "50px" }} />
                </ImageWrapper>
                <p><span style={{fontFamily:"Rubik-Medium"}}>{t("Company Surveys")} :</span> {this.props.t("Your Company has Launched a")} <a><span style={{fontFamily:"Rubik-Medium"}}>{`${t(item.data['survey_details']?.title)}`} </span></a>{this.props.t("Provide your Response  which will help them to Improve.")}</p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'challenge_invite': {
            const { invited_by: invitedBy } = (item.data && item.data['challenge_details']) || {};
            const {title, id} = item.data['challenge_details'];
            const { fname, lname } = invitedBy;
            return isEmpty(item.data) ? '' : buttonAll ?
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, item.type, item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${fname} ${lname}`}</b><span> {this.props.t("invited you to join the")} </span><a>{`${this.props.t(title)} challenge`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li> :
              !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, item.type, item.type_id)}>
                <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                  <img src={'/public/images/notification/challenge_notification_icon.png'} />
                </ImageWrapper>
                <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("invited you to join the")} </span><a>{`${t(title)} ${t("challenge")}`}</a></p>
                <div className="showDot">
                  {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
          case 'comment_like': {
            const { fname, lname } = item.data['liked_by'];
            return isEmpty(item.data) ? '': buttonAll?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification('', 'comment_like', item.type_id, item.data.post_comment_details.post_id)}>
            <ImageWrapper  width={'30px'}>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
              <p><b>{`${fname} ${lname}`}</b><span> {this.props.t("likes")} </span><a> {this.props.t("your comment")} :</a></p>
              <p style={{textOverflow: "ellipsis", whiteSpace: "nowrap",overflow: "hidden"}}>"{this.removeHtmlTags(item.data.post_comment_details.comment)}"</p>
              <div className="showDot">
                {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>:
            !item.read_status && <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification('', 'comment_like', item.type_id, item.data.post_comment_details.post_id)}>
            <ImageWrapper  width={'30px'}>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("likes")} </span><a> {t("your comment")} :</a></p>
            <p style={{textOverflow: "ellipsis", whiteSpace: "nowrap",overflow: "hidden"}}>"{this.removeHtmlTags(item.data.post_comment_details.comment)}"</p>
            <div className="showDot">
              {item.read_status ? <div className="color"></div> : <div className="no-color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
          }
          default:
            return (item.type !== '') ? null : <li style={{ padding: '17px', cursor: 'default' }}>{t("No notifications")}</li>
        }
      })
    }
  };

  classifyNotification = (userNotificationList, startIndex, endIndex) => {
    const notificationSlicedArray = this.state.seeMore ? userNotificationList : userNotificationList.slice(startIndex, endIndex);
    const { t, userId, user } = this.props;
    console.log()
    return notificationSlicedArray.map((item, index) => {
      const { read_status: readStatus } = item;
      const prevDate = item['created_at'];
      const firstdate = moment(prevDate);
      const date = new Date();
      const currentdate = date;
      let diff = Math.abs(getMainConstraint(firstdate, currentdate));
      let newResult = [];
      for (let name in units) {
        let p = Math.floor(diff / units[name]);
        if (newResult.length < 2) {
          if (p === 1) newResult.push(p + " " + name);
          if (p >= 2) newResult.push(p + " " + name + "s");
          diff %= units[name];
        }
      }
      if (newResult.length === 0 || (newResult[0].indexOf("minute") >= 0) || (newResult[0].indexOf("hour") >= 0)) {
        newResult = [];
        newResult.push(t("Today"));
      } else {
        newResult[0] = newResult[0] + " " + t('ago');
      }
      switch (item.type) {
        case 'reply_recognition': {
          const { fname, lname } = item.data['posted_by'];
          const { shoutout_type: shoutoutType } = item.data['recognition_details'];
          const { recognition_image: imageUrl } = item.data['recognition_details'];
          const { core_value: coreValue } = item.data['recognition_details'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', item.type, item.type_id)}>
            <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
              <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
            </ImageWrapper>
            <p>
              <a>{`${fname} ${lname}`}</a>
              <span>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? ` ${t('is saying')} ` : ` ${t("is recognizing")} `}</span><Text>{`${t(coreValue)} `}</Text>
              <a>{coreValue === 'Congrats' || coreValue === 'Thank You' || coreValue === 'Job Well Done' ? t(' to you ') : null}{` ${item.type === 'recognition' ? '' : item.type === 'comment_recognition' ? t('in a comment') : item.type === 'reply_recognition' ? t('in a comment reply') : null}`}</a>
            </p>
            <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'add_new_hris_users':{
          return(
            <li onClick={() => this.clickHris('', 'add_new_hris_users', item.type_id)}>
              <ImageWrapper width="50px" height="50px">
                <img onError={this.fallBack} src={`${ImageUrl}/${"Hris-image/hrisNotification.svg"}`} />
              </ImageWrapper>
              <p>
                <span>Hi {user?.fname},</span>&nbsp;
                <b>{item?.data?.new_user_count} users</b>&nbsp;
                <span>we’re added to {user?.company_name} from your HRIS integration.</span>
              </p>
              <div className="showDot">
                {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
          )
        }
        case 'recognition':{
            const { fname, lname } = item.data['posted_by'];
            const { shoutout_type: shoutoutType } = item.data['recognition_details'];
            const { recognition_image: imageUrl } = item.data['recognition_details'];
            const { core_value: coreValue } = item.data['recognition_details'];
            return (item?.data?.taggedUserCount===1|| item?.data?.taggedUserCount>1 ||item?.data?.tagged_count===1 || item?.data?.tagged_count>1)?<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
              {(item?.data?.taggedUserCount===1|| item?.data?.tagged_count===1 )?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives you")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition")}</span>
                </p>:
                item?.data?.taggedUserCount===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.lname}`}</b>
                </p>:
                item?.data?.tagged_count===2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.second_mention?.fname} ${item?.data?.second_mention?.lname}`}</b>
                </p>:
                item?.data?.tagged_count>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.tagged_count-1} others`}</b>
                </p>:
                item?.data?.taggedUserCount>2?
                <p>
                  <b>{`${fname} ${lname}`}</b> <span>{("gives")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition to")}</span>&nbsp;<b>{`you and ${item?.data?.taggedUserCount-1} others`}</b>
                </p>:null
              }
              <div className="showDot">
                {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>:<li key={index} className={readStatus && 'read'}   onClick={() => this.clickFeedNotification('', item.type, item.type_id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
              <p>
                <b>{`${fname} ${lname}`}</b> <span>{("gives you have")}</span>&nbsp;<b>{this.props.t(coreValue)}</b>&nbsp;<span>{this.props.t("recognition")}</span>
              </p>
              <div className="showDot">
                {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
          }
        case 'add_event': {
          const { title } = item.data['event_details'] !== undefined ? item.data['event_details'] : {};
          const { id } = item.data['event_details']
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_event', item.type_id)}>
            <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
              <img src={'/public/images/notification/event_notification_icon.png'} />
            </ImageWrapper>
            <p><b>{t("Woliba Event")} : </b><a>{`${t(title)} ${t("Event")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'add_initiative': {
          const { id, initiative_name, initiative_image } = item.data['initiative_details'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'add_initiative', item.type_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${initiative_image}`} alt="initiative" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
            </ImageWrapper>
            <p><b>{t("Company initiative")} :</b><a> {`${this.props.t("Your Company has Launched")} ${this.getArticle(initiative_name)}`} </a><b>{t(initiative_name)} </b><a>{t("initiative to complete")}.</a></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'event': {
          const { title, id } = item.data['event_details'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event', item.type_id)}>
            <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
              <img src={'/public/images/notification/event_notification_icon.png'} />
            </ImageWrapper>
            <p><b>{t("Woliba Event")} : </b><a>{`${t(title)} ${t("Event")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'event_end': {
          const { title, event_points: eventPoints, id } = item.data['event_details'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'event_end', item.type_id)}>
            <ImageWrapper backgroundColor={'linear-gradient(90deg,rgb(0,177,188) -1.11%,rgb(117,217,163) 97.46%)'}>
              <img src={'/public/images/notification/event_notification_icon.png'} />
            </ImageWrapper>
            <p><b>{`${t("Event")}  ${t(title)} `}</b><span>{t("Running event has ended")}</span><a>{` ${t("you've earned")} ${eventPoints} ${t("pts")}`}</a></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'recognition_other': {
          const { recognition_image: imageUrl } = item.data['recognition_details'];
          const { core_value: coreValue } = item.data['recognition_details'];
          return (item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)||(item?.data?.taggedUserCount>1)?<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
            {(item?.data?.tagged_count===1)||(item?.data?.taggedUserCount===1)?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.recognized_to?.fname} `}{`${item?.data?.recognized_to?.lname}. `}</b></p>:
            item?.data?.taggedUserCount===2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.second_mention?.fname} `}{`${item?.data?.second_mention?.lname}. `}</b></p>:
            item?.data?.taggedUserCount>2?
              <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>{this.props.t("gives")}</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.first_mention?.fname} `}{`${item?.data?.first_mention?.lname} `}</b><span>and</span><b>{` ${item?.data?.taggedUserCount-1} others.`}</b></p>:
              null
            }
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>:<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'recognition_other',item.type_id, item.data.recognition_details.id)}>
              <ImageWrapper width={coreValue === 'People' ? '30px' : null}>
                <img onError={this.fallBack} src={`${ImageUrl}/${imageUrl}`} />
              </ImageWrapper>
            <p><b>{`${item?.data?.posted_by?.fname} `}{`${item?.data?.posted_by?.lname} `}</b><span>gives</span>&nbsp;<b>{item?.data?.recognition_details?.core_value}</b>&nbsp;<span>{("recognition to")}</span><b>{` ${item?.data?.recognized_user?.fname} `}{`${item?.data?.recognized_user?.lname}. `}</b></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'post_mention_other': {
          return item.data.posted_by.uid==userId?null:<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.mentioned_other_user.fname} `}{`${item.data.mentioned_other_user.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'post_mention_other_2': {
          return item.data.posted_by.uid==userId?null:<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_2',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span>{this.props.t("and")}</span><b>{` ${item.data.second_mention.fname} `}{`${item.data.second_mention.lname} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'post_mention_other_3': {
          return item.data.posted_by.uid==userId?null:<li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention_other_3',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span><b>{` ${item.data.first_mention.fname} `}{`${item.data.first_mention.lname} `}</b><span> {this.props.t("and")}</span><b>{` ${item.data.mentioned_user.length-1} ${this.props.t("others")} `}</b><span> {this.props.t("in a post")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'post_mention': {
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_mention',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            {
            item.data.mention_others?
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{item.data.mention_others} {this.props.t("others")}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
            :item.data.mentioned_user.length>1
            ?
              <p><span><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b></span><span>{this.props.t("mentioned")}</span>&nbsp;<b>{this.props.t("you")}</b>&nbsp;<span>{this.props.t("and")}</span>&nbsp;<b>{userId==item.data?.first_mention?.uid ? item.data?.second_mention?.fname :item.data?.first_mention?.fname}</b>&nbsp;<span>{this.props.t("in a post")}</span></p>
              :
              <p><b>{`${item.data.posted_by.fname} `}{`${item.data.posted_by.lname} `}</b><span>{this.props.t("mentioned you in a post")}</span></p>}
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'tagged_post_comment': {
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_post_comment',item.type_id, item.data.comment_details[0].post_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.commented_by.fname} `}{`${item.data.commented_by.lname} `}</b><span>{this.props.t("commented on Post you are tagged in.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'tagged_comment_reply': {
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'tagged_comment_reply',item.type_id, item.data.reply_details.post_id)}>
            <ImageWrapper>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${item.data.replied_by.fname} `}{`${item.data.replied_by.lname} `}</b><span>{this.props.t("replied on Post You are tagged in.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'comment_recognition': {
          const { fname, lname } = item.data['posted_by'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotificationCommentMention("", 'comment_recognition',item.type_id, item.data.recognition_details.post_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.posted_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${fname} ${lname} `}</b><span>{this.props.t("mentioned you in")} <b>{`${item.data.post_details&& item.data.post_details.fname} ${item.data.post_details&&item.data.post_details.lname} `}</b> {this.props.t("post comment.")}</span></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'add_user_anniversary': {
          return <li key={index} className={readStatus && 'read'} >
            <ImageWrapper >
            {userId!=item.data.user_details.uid?
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
              <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/anniversaryNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
            </ImageWrapper>
            {userId!=item.data.user_details.uid?<p><span>{this.props.t("Give Shout out")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b><span>{` ${this.props.t("on")} ${item.data.user_details.anniversary_num} ${this.props.t("Year Work Anniversary")} ` }({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
            <p><span>{this.props.t("Congratulations")}</span><b>{`, ${item.data.user_details.fname} ${item.data.user_details.lname}`}</b> <span>{this.props.t("for Successful")}</span><b> {` ${item.data.user_details.anniversary_num} ${this.props.t("Year at")} ${item.data.user_details.company_name}!`}</b></p>}
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
              <div className='button' style={{width:"100px !important"}}>
                <div className='wish' onClick={()=>this.peerCallBack(1, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Shout Out")}</div>
              </div>
              <div className='time'>- {newResult[0]}</div>
            </div>:
            <h5>- {newResult[0]}</h5>}
          </li>
        }
        case 'add_user_birthday': {
          return <li key={index} className={readStatus && 'read'} >
            <ImageWrapper >
            {userId!=item.data.user_details.uid?
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.user_details.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>:
              <img onError={this.fallBack} src={ImageUrl+"/"+"social-feed/birthdayNotification.png"} alt="profile" style={{height:'50px', width:"50px", borderRadius:"50%"}}/>}
            </ImageWrapper>
            {userId!=item.data.user_details.uid?<p><span>{this.props.t("Wish")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{this.props.t("a happy birthday.")} ({convertMonthFromDate(moment(firstdate, 'MMM DD').format('MMM DD'), this.props)})</span></p>:
            <p><span>{this.props.t("Hey")}</span><b>{` ${item.data.user_details.fname} ${item.data.user_details.lname}  `}</b><span>{("Wish You a Happy Birthday!")}</span></p>}
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            {userId!=item.data.user_details.uid?<div className='buttonWrapper'>
              <div className='button' style={{width:"100px"}}><div className='wish' onClick={()=>this.peerCallBack(0, item.data.user_details.fname+" "+item.data.user_details.lname, item.data.user_details.uid, item.data.user_details.profile_image)}>{this.props.t("Wish Them")}</div></div>
              <div className='time'>- {newResult[0]}</div>
            </div>:
            <h5>- {newResult[0]}</h5>}
          </li>
        }
        case 'post_like': {
          // const { fname, lname } = item.data['liked_by'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'post_like',item.type_id, item.data.post_details.id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${t(item.data && item.data.liked_by && item.data.liked_by.fname && item.data.liked_by.fname)} ${t(item.data && item.data.liked_by && item.data.liked_by.lname && item.data.liked_by.lname)}`}</b><span> {t("likes")} </span><a> {t("your post")}</a></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'post_comment': {
          // const { fname, lname } = item.data['commented_by'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification('', 'post_comment', item.type_id, item.data.comment_details[0].post_id)}>
            <ImageWrapper >
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.commented_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${t(item.data && item.data.commented_by && item.data.commented_by.fname && item.data.commented_by.fname)} ${t(item.data && item.data.commented_by && item.data.commented_by.lname && item.data.commented_by.lname)}`}</b><span> {t("commented")} </span><a> {t("on your post")}</a></p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        case 'challenge_end': {
          const { title, id, challenge_point: challengePoint } = item.data['challenge_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge_end', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                <img src={'/public/images/notification/challenge_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{`${t("Challenge")}  ${t(title)}`}</b><span> {t("Running challenge has ended")} </span><a>{`${t("you've earned")} ${challengePoint} ${t("pts")}`}</a></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'challenge': {
          const { title, id } = item.data['challenge_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, 'challenge', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                <img src={'/public/images/notification/challenge_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{t("Woliba Challenge")} : </b><a>{`${t(title)} ${t("Challenge")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]} {t("ago")}</h5>
            </li>
        }
        case 'add_challenge': {
          // const { title, id } = item.data['challenge_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(item.data && item.data.challenge_details && item.data.challenge_details.id && item.data.challenge_details.id, 'add_challenge', item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                <img src={'/public/images/notification/challenge_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{t("Woliba Challenge")} : </b><a>{`${t(item.data && item.data.challenge_details && item.data.challenge_details.title && item.data.challenge_details.title)} ${t("Challenge")}`}</a><span> {t("is here")} </span><b>{t("Join now")}</b></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }

        case 'add_launch_quiz': {
          // const { title, id } = item.data['challenge_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(item.data && item.data.launch_quiz_details && item.data.launch_quiz_details.id && item.data.launch_quiz_details.id, 'add_launch_quiz', item.type_id)}>
              <ImageWrapper >
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.launch_quiz_details.image}`} alt="Quiz" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
                </ImageWrapper>
              <p><a>{`${t(item.data && item.data.launch_quiz_details && item.data.launch_quiz_details.title && item.data.launch_quiz_details.title)}`}</a> <b>{t("Join the Fun and Take the Quiz!")} </b></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'challenge_comment': {
          const { title, challenge_id: challengeId } = item.data['challenge_comment_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(challengeId, 'challenge_comment', item.type_id)}>
              <ImageWrapper backgroundColor={'#69c2ff'}>
                <img src={'/public/images/notification/comment_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{`${t(item.data && item.data.challenge_comment_details && item.data.challenge_comment_details.fullname && item.data.challenge_comment_details.fullname)}`}</b><span> {t("commented on")} </span><a> {`${t(title)}`}</a> {t("Challenge")}</p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'event_comment': {
          const { fullname, title, event_id: eventId } = item.data['event_comment_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(eventId, 'event_comment', item.type_id)}>
              <ImageWrapper backgroundColor={'#69c2ff'}>
                <img src={'/public/images/notification/comment_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{`${t(fullname)}`}</b><span> {t("commented on")} </span><a> {`${t(title)}`}</a> {t("Event")}</p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'friend_request': {
          const { fname, lname, profile_image } = item.data['sent_by'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'friend_request', item.type_id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
              </ImageWrapper>
              <p><span>{t("You Have a Buddy Request")} : </span> <b>{`${t(fname)} ${t(lname)}`}</b></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'device_disconnect': {
          const { device_name: deviceName } = item.data && item.data['device_details'] ? item.data['device_details'] : {};
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'device_disconnect', item.type_id)}>
              <ImageWrapper backgroundColor={'#FD7175'}>
                <img src={'/public/images/notification/device.png'} />
              </ImageWrapper>
              <p>{t("Your device")} <b>{`${t(deviceName)}`}</b><span> {t("got disconnected from Woliba")}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'photo_post': {
          const { fname, lname } = item.data['posted_by'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", 'photo_post', item.data.post_details.id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.post_details.image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("posted a photo.")}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'request_accept_or_reject': {
          const { status } = item.data['friend_request_details'];
          const { fname, lname, profile_image } = item.data['invitee_id'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification('', 'request_accept_or_reject', item.type_id)}>
              <ImageWrapper >
                <img onError={this.fallBack} src={`${ImageUrl}/${profile_image}`} alt="profile" style={{ "width": "50px", "height": "50px", "border-radius": "50%" }} />
              </ImageWrapper>
              <p><b>{`${t(fname)} ${t(lname)}`}</b><span>{` ${t("has")} ${t(status)} ${t("your Buddy Request")}`}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'post_comment_reply':{
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
              <ImageWrapper  width={'30px'}>
                <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
              </ImageWrapper>
              <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your")} ${item.type === 'comment_reply' ? t('comment') : t('post')}`}</span></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'comment_reply': {
            return isEmpty(item.data) ? '' :
              <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification("", item.type,item.type_id, item.data.reply_details.post_id)}>
                <ImageWrapper  width={'30px'}>
                  <img onError={this.fallBack} src={`${ImageUrl}/${item.data.replied_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
                </ImageWrapper>
                <p><b>{`${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.fname)} ${t(item.data && item.data.replied_by && item.data.replied_by.fname && item.data.replied_by.lname)}`}</b><span>{` ${t("replied to your comment of")} `}</span><b>{`${item.data.posted_user_details&& item.data.posted_user_details.fname} ${item.data.posted_user_details&& item.data.posted_user_details.lname} `}</b>Post</p>
                <div className="showDot">
                {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
                </div>
                <h5>- {newResult[0]}</h5>
              </li>
          }
        case 'add_survey': {
          const { title: surveyName, id } = item.data['survey_details'];
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.takeSurvey(item.type_id, id,item.data)}>
              <ImageWrapper >
                <img src={'/public/images/notification/survey_icon.png'} style={{ "width": "50px", "height": "50px" }} />
              </ImageWrapper>
              <p><span style={{fontFamily:"Rubik-Medium"}}>{this.props.t("Company Surveys")} :</span> {this.props.t("Your Company has Launchd a")} <a><span style={{fontFamily:"Rubik-Medium"}}>{`${item.data['survey_details']?.title}`} </span></a>{this.props.t("Provide your Response  which will help them to Improve.")}</p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'challenge_invite': {
          const { invited_by: invitedBy } = (item.data && item.data['challenge_details']) || {};
          const {title, id} = item.data['challenge_details']
          const { fname, lname } = invitedBy;
          return isEmpty(item.data) ? '' :
            <li key={index} className={readStatus && 'read'} onClick={() => this.clickNotification(id, item.type, item.type_id)}>
              <ImageWrapper backgroundColor={'linear-gradient(180deg,#006FBC 0%,#7F43FF 100%)'}>
                <img src={'/public/images/notification/challenge_notification_icon.png'} />
              </ImageWrapper>
              <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("invited you to join the")} </span><a>{`${t(title)} ${t("challenge")}`}</a></p>
              <div className="showDot">
              {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
              </div>
              <h5>- {newResult[0]}</h5>
            </li>
        }
        case 'comment_like': {
          const { fname, lname } = item.data['liked_by'];
          return <li key={index} className={readStatus && 'read'} onClick={() => this.clickFeedNotification('', 'comment_like', item.type_id, item.data.post_comment_details.post_id)}>
            <ImageWrapper  width={'30px'}>
              <img onError={this.fallBack} src={`${ImageUrl}/${item.data.liked_by.profile_image}`} alt="profile"  style={{height:'50px', width:"50px", borderRadius:"50%"}}/>
            </ImageWrapper>
            <p><b>{`${t(fname)} ${t(lname)}`}</b><span> {t("likes")} </span><a> {t("your comment :")}</a></p><br/>
            <p style={{textOverflow: "ellipsis", whiteSpace: "nowrap",overflow: "hidden"}}>"{this.removeHtmlTags(item.data.post_comment_details.comment)}"</p>
            <div className="showDot">
            {item.read_status ? <div className="no-color"></div> : <div className="color"></div>}
            </div>
            <h5>- {newResult[0]}</h5>
          </li>
        }
        default:
          return (item.type !== '') ? null : <li style={{ padding: '17px', cursor: 'default' }}>{t("No notifications")}</li>
      }
    })
  };

  displayDeviceModal = () => this.setState({ showDeviceModal: true });
  hideDeviceModal = () => {
    this.setState({ showDeviceModal: false });
  };
  // takeSurvey = (notificationId, id) => {
  //   this.clickNotification('', 'add_survey', notificationId);
  //   this.props.fetchSurveyQuestions(id, this.props.history)
  // }

  takeSurvey = (notificationId, id,survey) => {
  window.console.log("survey", survey);

  const { history, notificationReadStatus, getReadStatusCountForNotification, fetchGetUserNotification } = this.props;
  getReadStatusCountForNotification();
  fetchGetUserNotification();
  let obj = {};
  obj['notification_type'] = "add_survey";
  obj['notification_type_id'] = notificationId;
  obj['read_status'] = 1;
  notificationReadStatus(obj);
  this.props.history.push({pathname:`/survey`, state:{surveyId:survey.survey_details.id, surveyPoints:survey?.survey_details?.config?.points, surveyCategory:survey['survey_details']?.category?.name}});
  this.toggleNotification();

    // location.reload();
  }

  buttonStatus = (all, unread) => {
    this.setState({ buttonAll: all, buttonUnread: unread });
  };

  handlePeerModal = () => {
    this.setState((prev) => ({
      showPeerModal: !prev.showPeerModal,
      shoutOutData: {}
    }));
  };

  render() {
    const { userNotificationList, readStatusCount, devices, revokeDevice, notificationMarkAsRead, color, t } = this.props;
    const { seeMore, showNotification, showDeviceModal, buttonAll, buttonUnread, showPeerModal } = this.state;

    if (isNull(userNotificationList) || isUndefined(userNotificationList) || isNull(readStatusCount) || isUndefined(readStatusCount)) {
      return <Waiting />
    }
    let unreadNotificationCount = 0;
    if (userNotificationList && userNotificationList.length > 0) {
      userNotificationList.map((data) => {
        if (data.read_status === 0) {
          unreadNotificationCount = unreadNotificationCount + 1;
        }
      })
    }
    return (
      <div ref={this.myref}>
        <IconContainer onClick={this.toggleNotification} active={this.state.showNotification ? 1 : 0} padding={'0px 25px 10px 10px'} color={color}>
          {notificationIcon}
          {readStatusCount.count > 0 ? <span> {unreadNotificationCount>9?"9+":unreadNotificationCount}</span> : ''}
        </IconContainer>
        {showNotification ? <NotificationWrapper id={'notificationPopup'} fontFamily={readStatusCount.count > 0 ? 1 : 0} height={seeMore ? "1" : 0}>
          <TopHeading>
            {t("Notifications")}
            {/* <img src={ImageUrl+"/"+"social-feed/filter.png"} alt="profile"/> */}
          </TopHeading>
          <Container>
            <div className="buttonWrapper" >
              <ReadUnreadButton active={buttonAll} onClick={() => this.buttonStatus(true, false)}>
                {t("All")}
              </ReadUnreadButton>
              <ReadUnreadButton active={buttonUnread} margin="1" onClick={() => this.buttonStatus(false, true)}>
                {t("Unread")}
              </ReadUnreadButton>
            </div>
            <div className="readText" onClick={() => readStatusCount.count > 0 ? notificationMarkAsRead() : null}>
              {t("Mark all as read")}
            </div>
          </Container>
          <ul>
            {
              userNotificationList.length === 0 ? <li style={{ padding: '17px', cursor: 'default' }}>{t("No notifications")}</li> : buttonUnread ? this.classifyUnreadNotification(userNotificationList, 0, userNotificationList.length, unreadNotificationCount) : this.classifyNotification(userNotificationList, 0, userNotificationList.length)
            }
            {/* {userNotificationList.length > 5 ? <Button onClick={this.showList}>{seeMore ? 'See less notifications' : 'See all notifications'}</Button> : ""} */}
          </ul>
        </NotificationWrapper> : null}
        <ConnectDeviceModal
          showModal={showDeviceModal}
          closeModal={this.hideDeviceModal}
          devices={devices}
          revokeDevice={revokeDevice}
        />
                {
          this.state.showPeerModal && <PeerRecognition
            onClose={this.handlePeerModal}
            showModal={this.state.showPeerModal}
            history={this.props.history}
            enableTrigger={true}
            companyId={this.props.companyId}
            shoutOut={this.state.shoutOutData}
          />
        }
      </div>
    )
  }
}

Notification.propTypes = {
  history: PropTypes.object,
  notificationReadStatus: PropTypes.func,
  userNotificationList: PropTypes.array,
  fetchGetUserNotification: PropTypes.func,
  getReadStatusCountForNotification: PropTypes.func,
  readStatusCount: PropTypes.object,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func,
  fetchConnectedDevices: PropTypes.func,
  fetchSurveyQuestions: PropTypes.func,
  notificationMarkAsRead: PropTypes.func,
  color: PropTypes.string,
  t: PropTypes.func,
  userId: PropTypes.number,
  companyId: PropTypes.number.isRequired,
  user: PropTypes.string
};

const mapStateToProps = (state) => ({
  userNotificationList: state.userNotification.getUserNotification,
  readStatusCount: state.userNotification.readStatusCount,
  devices: state.wellnessDashboard.devices,
  userId: state.profileData.userId,
  user: state.profileData.user
});

const mapDispatchToProps = (dispatch) => ({
  notificationReadStatus: (updatedDate) => dispatch(notificationReadStatus(updatedDate)),
  fetchGetUserNotification: () => dispatch(fetchGetUserNotification()),
  getReadStatusCountForNotification: () => dispatch(getReadStatusCountForNotification()),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  fetchSurveyQuestions: (id, history) => dispatch(FetchSurveyQuestions(id, history)),
  notificationMarkAsRead: () => dispatch(notificationMarkAsRead())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Notification));